'use client';

import { useEffect, useState } from 'react';
import { Account } from 'shared/types/account';
import { Cart, Order, ShippingMethod } from 'shared/types/cart';
import { Payment } from 'shared/types/cart/Payment';
import BillingDetails from '~/components/furniturechoice/order-confirmation/billing-details';
import DeliveryDetails from '~/components/furniturechoice/order-confirmation/delivery-details';
import OrderSummary from '~/components/furniturechoice/order-confirmation/order-summary';
import RealCustomersRealHomes from '~/components/furniturechoice/order-confirmation/real-customers-real-homes';
import { TasticProps } from '~/frontastic/tastics/types';
import { sdk } from '~/sdk';
import { DataSourceV2 } from '~/types/datasource';
import { sendGA4TransactionEvent } from '~/utils/send-ga4-event';
import ActionBar from './ActionBar';
import EcologiMessage from './EcologiMessage';
import HeaderMessage from './HeaderMessage';
import RegistrationMessage from './registration-message';
import Steps from '../checkout-header/steps';
import buildAddress from '../information/information-content-manager/form/set-cart-addresses/build-address';

interface OrderConfirmationProps {
  payment: DataSourceV2<{
    payment: Payment;
    order: Order;
    cart?: Cart;
    shipping: ShippingMethod;
    ok?: boolean;
    message?: string;
  }>;
}

export default function OrderConfirmation({
  data: {
    payment: { dataSource },
  },
}: TasticProps<OrderConfirmationProps>) {
  sdk.defaultConfigure('en');
  const [isRegisteringAccount, setIsRegisteringAccount] = useState<boolean>(false);

  async function callRegisterAction(accountDetails: Record<string, FormDataEntryValue>) {
    const billing = buildAddress(accountDetails, 'billing');
    const shipping = buildAddress(accountDetails, 'shipping');

    const response = await sdk.callAction<Account>({
      actionName: 'account/register',
      payload: {
        billingAddress: billing,
        shippingAddress: shipping,
      },
    });

    if (!response.isError) {
      sessionStorage.removeItem('isRegisteringAccount');
      sessionStorage.removeItem('registerAccountDetails');
    }
  }

  useEffect(() => {
    const isRegisteringAccount = sessionStorage.getItem('isRegisteringAccount');

    if (isRegisteringAccount === 'yes') {
      setIsRegisteringAccount(true);
      const accountDetailsString = sessionStorage.getItem('registerAccountDetails');
      const accountDetails: Record<string, FormDataEntryValue> | null = accountDetailsString
        ? JSON.parse(accountDetailsString)
        : null;

      if (accountDetails) {
        callRegisterAction(accountDetails);
      }
    }
  }, []);

  // TODO: introduce a better way to display the error with correct messaging
  // be sure that a valid payment was found
  if (!dataSource.payment || dataSource.ok === false) {
    if (dataSource.payment) {
      console.log(dataSource.message);
      return <div className="container">{dataSource.message}</div>;
    } else {
      console.log('Payment not found');
      return <div className="container">Payment not completed</div>;
    }
  }

  // should not be able to reach this page if payment is still processing
  if (dataSource.payment.paymentStatus == 'init' || !dataSource.payment.paymentStatus) {
    console.log('Payment not completed');
    return <div className="container">Payment not completed</div>;
  }

  // no need to show summary if payment failed
  if (dataSource.payment.paymentStatus == 'failed') {
    return (
      <div className="py-8">
        <HeaderMessage paymentStatus="failed" />
      </div>
    );
  }

  // send GA4 event if successful
  sendGA4TransactionEvent('purchase', dataSource.order.lineItems ?? [], dataSource.order.orderNumber);

  // success or referred
  return (
    <div className="py-8">
      <div className="container grid gap-4 text-center">
        <HeaderMessage paymentStatus={dataSource.payment.paymentStatus} />
        {isRegisteringAccount && <RegistrationMessage />}
        <EcologiMessage />
        <div className="py-6">
          <Steps currentStep={4} />
        </div>
      </div>
      <ActionBar />
      <div className="print-area container grid gap-y-8">
        <div className="grid gap-8 lg:grid-cols-2">
          <DeliveryDetails order={dataSource.order || dataSource.cart} shippingMethod={dataSource.shipping} />
          <BillingDetails order={dataSource.order || dataSource.cart} payment={dataSource.payment} />
        </div>
        <div className="grid gap-8 lg:grid-cols-[auto_390px]">
          <OrderSummary order={dataSource.order || dataSource.cart} shippingMethod={dataSource.shipping} />
          <RealCustomersRealHomes />
        </div>
      </div>
    </div>
  );
}
